@import "../styles/minty";
@import "~bootstrap/scss/bootstrap";

body {
  text-align: center;
}

.brand {
  padding: 3em 0 2em 0;
  @include media-breakpoint-up(md) {
    padding: 4em 0 3em;
  }
  background-color: lighten($primary, 25);
}

.overlaid {
  color: lighten($success, 35);
  background-color: lighten($success, 35);
  border-color: darken($success, 20);
}
